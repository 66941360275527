<template>
  <div class="contractors-layout" fluid>
    <div class="d-flex flex-row align-center justify-space-between">
      <div>
        <router-link
          class="contractors-layout__link text-h5 text-md-h4 font-weight-bold mr-2"
          active-class="contractors-layout__link--active"
          exact-path
          :to="contractorsLink"
          >{{ $t('contractors.contractors') }}</router-link
        >
      </div>
    </div>

    <router-view @update:is-data-missing="updateIsDataMissing" />
  </div>
</template>

<script>
import { CONTRACTORS } from '@/constants/routes';

export default {
  name: 'ContractorsLayout',

  inject: ['media'],

  data() {
    return {
      pages: {
        contractors: this.$route.query.page || 1,
        archive: this.$route.query.page || 1,
      },

      isDataMissing: false,
    };
  },

  computed: {
    contractorsLink() {
      return { name: CONTRACTORS, query: { page: this.pages.contractors } };
    },

    isContractorsPage() {
      return this.$route.name === CONTRACTORS;
    },
  },

  watch: {
    // eslint-disable-next-line
    '$route.query.page': function(newVal) {
      if (this.isContractorsPage) {
        this.pages.contractors = newVal;
        return;
      }

      this.pages.archive = newVal;
    },
  },

  methods: {
    updateIsDataMissing(value) {
      this.isDataMissing = value;
    },
  },
};
</script>

<style lang="scss">
.contractors-layout {
  height: 100%;

  &__link {
    color: rgba($--black-color-0, 0.5) !important;
    text-decoration: none;

    &--active {
      color: $--black-color-0 !important;
      text-decoration: underline;
    }
  }
}
</style>
